import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '@rflex-spa/react-core/dist/base/hooks/useAuth';
// utils
import { isValidSession, getSession } from '@rflex-spa/react-core/dist/base/utils/jwt';
import { isNotEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';
//
import { AUTH_ROUTES, ROUTES } from '../routes/paths';

// ----------------------------------------------------------------------

export default function IndexRedirect() {
  const { isAuthenticated } = useAuth();
  const currentSession = getSession();

  if (!isAuthenticated) {
    return <Navigate to={AUTH_ROUTES.login} replace />;
  }

  let redirectTo = ROUTES.home;

  if (isValidSession(currentSession) && isNotEmpty(currentSession.instanceSelected)) {
    const { userData } = currentSession.instanceSelected;

    if (userData.role === 'worker') {
      redirectTo = ROUTES.teletrabajo;
    }
  }

  return <Navigate to={redirectTo} replace />;
}

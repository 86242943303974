import { createSlice } from '@reduxjs/toolkit';
// api
import { isNotEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';
import { getUserPermissions } from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  userPermissions: null,
};

const MINIMUN_USER_PERMISSIONS = {
  rol: {
    code: 'minimun-user-role',
    name: 'Minimun user role',
  },
  routes: [
    { 'code': 'home' },
    { 'code': 'user-profile' },
  ],
};

const slice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.userPermissions = {
        rol: {
          code: null,
          name: null,
        },
        routes: [],
      };
      state.isLoading = false;
      state.error = action.payload;
    },

    setUserPermissions(state, action) {
      state.userPermissions = action.payload;
      state.isLoading = false;
      state.error = null;
    },

    reset: () => initialState,
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUserPermissions } = slice.actions;

// ----------------------------------------------------------------------

export function requestUserPermissions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const userPermissions = await getUserPermissions();

      if (isNotEmpty(userPermissions)) {
        dispatch(slice.actions.setUserPermissions(userPermissions));
      } else {
        /**
         * TODO: show a restriction page if user doesn't have any assigned roles.
         *
         * Load the minimun permissions if the user doesn't have any roles or permissions aasigned
         */
        dispatch(slice.actions.setUserPermissions(MINIMUN_USER_PERMISSIONS));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetUserPermissions() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}

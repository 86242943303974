import PropTypes from 'prop-types';

import Freshchat from '../Freshchat';
import ForcedReloadModal from '../custom/ForcedReloadModal';

// ------------------------------------------------------

PageWrapper.propTypes = {
  children: PropTypes.node,
};

export default function PageWrapper({ children }) {
  return (
    <>
      <ForcedReloadModal />
      <Freshchat />
      {children}
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';
// local
import config from '../../config/config';
// api
import { getAppConfig } from '../../api';
import { getInspectorAppConfig, getInspectorColaboratorsMinData, getInspectorWorkingDayTypes } from '../../views/work-address/certification-reports/api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  appConfig: {},
};

const slice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setAppConfig(state, action) {
      state.appConfig = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAppConfig } = slice.actions;

// ----------------------------------------------------------------------

// Seteo de configuraciones para los usuarios de tipo Admin
export function requestAppConfig(clinic) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const data = await getAppConfig();

      const appConfig = {
        ...data,
        clinicalConfig: config[clinic] || {},
        defaultConfig: config.default,
      };

      dispatch(slice.actions.setAppConfig(appConfig));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Seteo de configuraciones para los usuarios de tipo DT
export function requestDTAppConfig(requestData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const data = await getInspectorAppConfig(requestData);
      const workersData = await getInspectorColaboratorsMinData(requestData);
      const workingDayTypes = await getInspectorWorkingDayTypes(requestData);

      const updatedAppConfig = {
        ...data.appConfig,
        trabajadoresIniciales: workersData,
        tiposJornada: workingDayTypes
      };

      dispatch(slice.actions.setAppConfig(updatedAppConfig));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

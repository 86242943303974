import React, { useState, useEffect } from 'react';
import { isEmpty, isNotEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';

import ResponseModal from './ResponseModal';

const INSTANCE_CHANGED_STATE = {
  title: "Cambio de instancia detectado",
  message: `Se ha detectado un cambio de instancia en otra pestaña.\nSe necesita recargar la página para continuar.`,
};

const reloadPage = () => {
  window.location.reload();
};

export default function ForcedReloadModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === 'userAuth') {
        const oldValue = event.oldValue ? JSON.parse(event.oldValue) : null;
        const newValue = event.newValue ? JSON.parse(event.newValue) : null;

        // Evento de login
        if (isEmpty(oldValue?.instanceSelected) && isNotEmpty(newValue?.instanceSelected)) {
          reloadPage();
          return;
        }

        // Evento de logout
        if (isNotEmpty(oldValue?.instanceSelected) && isEmpty(newValue?.instanceSelected)) {
          reloadPage();
          return;
        }

        // Evento de cambio de instancia
        if (oldValue?.instanceSelected?.code !== newValue?.instanceSelected?.code) {
          setModalData(INSTANCE_CHANGED_STATE);
          setIsModalOpen(true);
        }
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  const closeModal = () => {
    setModalData(null);
    setIsModalOpen(false);

    reloadPage();
  };

  return (
    <ResponseModal
      open={isModalOpen}
      type="warning"
      title={modalData?.title}
      message={modalData?.message}
      closeText="Recargar página"
      onCancel={closeModal}
      disableEscapeKeyDown
    />
  );
};

import PropTypes from 'prop-types';
import { Icon, Stack, Typography, useTheme } from '@mui/material';
import CustomDialog from '@rflex-spa/react-core/dist/base/components/custom/CustomDialog';
import Iconify from '@rflex-spa/react-core/dist/base/components/Iconify';
import { isEmpty, isNotEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';

ResponseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'custom']).isRequired,
  iconSrc: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
};

export default function ResponseModal({
  open,
  onClose,
  onCancel,
  title = '¡Éxito!',
  message = 'La acción se completó correctamente. Los cambios han sido guardados con éxito.',
  type = 'success',
  iconSrc = '',
  iconColor = 'currentColor',
  iconSize = 130,
  ...other
}) {
  const theme = useTheme();

  const { alert } = theme.appTheme.component;

  // Función para obtener el texto alternativo (alt) dependiendo del tipo
  const getAltText = () => {
    switch (type) {
      case 'success':
        return 'Acción exitosa';
      case 'warning':
        return 'Advertencia';
      case 'error':
        return 'Error';
      case 'custom':
        return 'Acción realizada';
      default:
        return 'Ícono de notificación';
    }
  };

  const getColor = () => {
    const isCustom = type === 'custom';

    if (isCustom) {
      return isEmpty(iconColor) ? 'currentColor' : iconColor;
    }

    return alert[type].text.primary;
  };

  // Renderiza el ícono según el tipo o una ruta personalizada
  const _renderIcon = () => {
    // Íconos predeterminados para cada tipo de mensaje
    const defaultIcons = {
      success: '/assets/icons/actions/Icon_Check.svg',
      warning: '/assets/icons/actions/Icon_Exclamation.svg',
      error: 'mdi-close-circle-outline',
      info: 'mdi-information-outline',
      custom: iconSrc,
    };

    // Determina el ícono a utilizar
    const icon = defaultIcons[type];

    // Verifica si es una ruta SVG o un identificador para Iconify
    const isSvgPath = isNotEmpty(icon) && icon.startsWith('/assets/');

    if (isSvgPath) {
      // Si es una ruta SVG, renderiza la imagen
      return (
        <Icon sx={{ width: 'auto', height: `${iconSize}px` }}>
          <img src={icon} style={{ width: `${iconSize}px`, height: `${iconSize}px` }} alt={getAltText()} />
        </Icon>
      );
    }

    // Si no es una ruta SVG, renderiza un ícono de Iconify
    return (
      <Iconify
        icon={isNotEmpty(icon) ? icon : defaultIcons.info}
        sx={{
          color: getColor(),
          height: `${iconSize}px`,
          width: `${iconSize}px`,
        }}
      />
    );
  };

  return (
    <CustomDialog open={open} onClose={onClose} onCancel={onCancel} title={title} maxWidth="sm" {...other}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Stack pt={2}>{_renderIcon()}</Stack>

        <Stack pt={3} mb={-4}>
          <Typography textAlign="center">{message}</Typography>
        </Stack>
      </Stack>
    </CustomDialog>
  );
}
